import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout'
import config from '../product/config'
import http from "../product/js/http"
import permit from "../product/js/permit"
import {Message} from 'element-ui' 

Vue.use(VueRouter)

const PERMIT_PASSWORD = "me/password"
const FIRST_PAGE = config.debug ? "/project/index" : "/project/index" 


export const commRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/main/Login.vue')
  },
  {
    path: '/password',
    name: 'password',
    permit: PERMIT_PASSWORD,
    component: () => import('../views/me/Password.vue')
  },
]
export const routes=[].concat(commRoutes)

function permitRoutes(){
  return [
    {
      path: '/me',
      permit: "me",
      component: Layout,
      children: [{
        path: 'password',
        permit: PERMIT_PASSWORD,
        name: 'Password',
        component: () => import("../views/me/Password.vue"),
        meta: { title: '修改密码', icon: 'el-icon-s-help' }
      }]
    },
    {
      path: '/admin',
      permit: "admin",
      meta: { title: '系统管理', icon: 'el-icon-s-help' },
      component: Layout,
      children: [{
        path: 'role',
        permit: "admin/role",
        name: 'Role',
        component: () => import("../views/admin/Role.vue"),
        meta: { title: '角色管理', icon: 'el-icon-s-help' }
      },{
        path: 'user',
        permit: "admin/user",
        name: 'User',
        component: () => import("../views/admin/User.vue"),
        meta: { title: '用户管理', icon: 'el-icon-s-help' }
      },{
        path: 'ip',
        permit: "admin/ip",
        name: 'IP',
        component: () => import("../views/admin/IP.vue"),
        meta: { title: '白名单', icon: 'el-icon-s-help' }
      }]
    },
    {
      path: '/project',
      // permit:"project", project是默认拥有的目录权限
      component: Layout,
      children: [{
        path: 'index',
        name: 'project',
        component: () => import("../views/project/Home.vue"),
        meta: { title: '项目管理', icon: 'dashboard',affix: true }
      }]
    },
    {
      path: '/company',
      permit: "company",
      component: Layout,
      children: [{
        path: 'search',
        name: 'CompanySearch',
        component: () => import("../views/company/Search.vue"),
        meta: { title: '企业管理', icon: 'el-icon-s-help' }
      }]
    },
    {
      path: '/log',
      permit: "log",
      meta: { title: '日志管理', icon: 'el-icon-s-help' },
      component: Layout,
      children: [{
        path: 'login_log',
        name: 'LoginLog',
        component: () => import("../views/log/LoginLog.vue"),
        meta: { title: '登录日志', icon: 'el-icon-s-help' }
      },{
        path: 'opera_log',
        name: 'OperaLog',
        component: () => import("../views/log/OperaLog.vue"),
        meta: { title: '操作日志', icon: 'el-icon-s-help' }
      }]
    },
    {
      path: '/dev',
      permit: 'dev',
      component: Layout,
      children: [{
        path: 'index',
        name: 'devIndex',
        component: () => import("../views/dev/Home.vue"),
        meta: { title: '开发工具', icon: 'el-icon-s-help' }
      }]
    },
    {
      path: '/logout',
      component: Layout,
      children: [{
        path: 'logout',
        name: 'logout',
        component: () => import("../views/main/Logout.vue"),
        meta: { title: '注销', icon: 'el-icon-s-help' }
      }]
    },
  ]
}
 

function buildRouter(routers,permits){
  let res = []
  for(let it of routers){
    if(it.permit!=undefined && !permit.has(permits,it.permit)){
      delete it['permit']
      continue
    }
    delete it['permit']
    if(it.children!=undefined && it.children.length>0){
      it.children = buildRouter(it.children,permits)
    }
    res.push(it)
  }
  return res
}

function queryFirstPath(routers,head=true){
  let lst = []
  for(let it of routers){
    if (it.children == null || it.children.length ==0){
      lst.push(it.path)
      continue
    }
    let children_paths = queryFirstPath(it.children,false)
    for(let iit of children_paths){
      lst.push(it.path + "/" + iit)
    }   
  }
  if(head && lst.indexOf(FIRST_PAGE) != -1) {
      return [FIRST_PAGE]
  }
  console.log("lst:")
  console.log(lst)
  return lst
}

const createRouter = (more=[]) => new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: commRoutes.concat(more)
})

const router = createRouter([])

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
    return
  } 
  const token = config.getToken()
  if (!token) {
    next('/login')
    return
  }
  next()
})
export async function resetRouter() {
  let user = await http.get("/api/me")
  config.permits = user.res
  let lst = []
  if (user.reset && permit.has(config.permits,PERMIT_PASSWORD)){
    lst = [{path:"/",redirect:"/password"}]
  }else{
    lst = buildRouter(permitRoutes(),config.permits)
    lst = [{path:"/",redirect:queryFirstPath(lst)[0]}].concat(lst)
  }  
  config.routes = commRoutes.concat(lst)
  const newRouter = createRouter(lst)
  lst.forEach((it)=>{
    routes.push(it)
  })
  router.matcher = newRouter.matcher // reset router
}

export default router
