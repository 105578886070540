// import 
import http from "./js/http"
import config from "./config"


const $M = {
	get: http.get,
	post: http.post,
	delete:http.delete,
	put:http.put,
	setToken: config.setToken,
	getToken: config.getToken,
	debug: config.debug,
	config: config
}

const install = Vue => {
	Vue.prototype.$M = $M
}

export default {
	install
}