let g = {}

g.has = (res,permit)=>{
  for(let it of res){
    if (it.startsWith(permit)){
      // if (it == permit || it.startsWith(permit)){
        return true
    }
  }
  return false
}

export default g