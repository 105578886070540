
let g = {
    debug: process.env.NODE_ENV != 'production',
    baseURL: "/",
    timeout: 60000,
    headers: {},
    bearer: "",
    init: false,
    permits:[],
    routes:[]

}

g.setToken = (token) => {
    g.bearer = token
}

g.getToken = () => {
    return g.bearer
}

export default g