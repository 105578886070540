import axios from 'axios'
import config from "../config.js"
import {Message} from 'element-ui'

function http(args){
    let next = args == null ? null : args.next
    let c = axios.create({
        baseURL:config.baseURL,
        timeout:config.timeout,
        headers:config.headers,
        validateStatus:function(){
            return true
        }
    })
    if(config.bearer){
        c.defaults.headers.common['Authorization'] = 'Bearer ' + config.bearer
    }
    c.interceptors.response.use(res => {
        let code = res.status
        if(code == 200){
            if(next){
                next(true,res.data)
            }
            return Promise.resolve(res.data)
        }else {
            if(next){
                next(false,res.data)
            }
            if (typeof(res.data) == 'string'){
                Message.error(res.data) 
                return Promise.reject(res.data)
            }
            Message({
                message: res.data.detail,
                type: 'error',
                duration: 5 * 1000
            })  
            return Promise.reject(res.data.detail)
        }
    })
    return c
}

let g ={}

g.get = (args={})=>{
    if(typeof(args) == "string"){
        return http(null).get(args)
    }
    return http(args).get(args.url,{
        params:args.data
    })
}

g.delete = (args={})=>{
    if(typeof(args) == "string"){
        return http(null).delete(args)
    }
    return http(args).delete(args.url,{
        params:args.data
    })
}

g.post = (args={})=>{   
    if ('type' in args && args.type == 'form'){
        const qs = require('qs');
        return http(args).post(args.url,qs.stringify(args.data),{
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
        })
    }
    return http(args).post(args.url,args.data)
}

g.put = (args={})=>{
    if ('type' in args && args.type == 'form'){
        const qs = require('qs');
        return http(args).put(args.url,qs.stringify(args.data),{
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
        })
    }
    return http(args).put(args.url,args.data)
}

export default g