import Vue from 'vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import ElementUI from 'element-ui'
import './styles/element-variables.scss'
import './styles/index.scss' // global css

import App from './App.vue'
import router from './router'
import store from './store'
import product from './product'
import '@/icons'
// 分页组件
import Pagination from "@/components/Pagination";

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(product)
Vue.component('Pagination', Pagination)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
